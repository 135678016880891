<template>
  <div class="kt-widget__item">
    <span class="kt-media kt-media--circle">
      <img :src="image" alt="image" />
    </span>
    <div class="kt-widget__info">
      <div class="kt-widget__section">
        <span v-if="selectedUser.id === id" class="kt-widget__username">
          {{ name }}
        </span>
        <a
          v-else
          href="javascript:void(0)"
          v-on:click="selectUser(id)"
          class="kt-widget__username"
          >{{ name }}
        </a>
        <span
          v-if="parseInt(isOnline) === 1"
          class="kt-badge kt-badge--success kt-badge--dot"
        ></span>
      </div>
      <span class="kt-widget__desc">{{ userType }}</span>
    </div>
    <div class="kt-widget__action">
      <span
        v-if="parseInt(unread) !== 0"
        class="kt-badge kt-badge--success kt-font-bold"
        >{{ unread }}</span
      >
    </div>
  </div>
</template>

<script>
import { SELECT_USER } from "@/store/chat.module";
import { mapState } from "vuex";
import { getUserType } from "@/common/jwt.service";

export default {
  props: ["id", "image", "name", "type", "isOnline", "unread"],
  methods: {
    selectUser(user_id) {
      this.$store.dispatch(SELECT_USER, user_id).then(() => {
        let chatContent = document.getElementById("chat-content-scrollbar");
        this.$nextTick(() => {
          chatContent.scrollTop = chatContent.scrollHeight;
        });
      });
    }
  },
  computed: {
    ...mapState({
      selectedUser: state => state.chat.selectedUser
    }),
    userType() {
      if (getUserType() === "student") {
        return this.$t("GENERAL." + this.type.toUpperCase());
      } else {
        return this.type;
      }
    }
  }
};
</script>
