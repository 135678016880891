<template>
  <div
    class="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app"
  >
    <button class="kt-app__aside-close" id="kt_chat_aside_close">
      <i class="la la-close"></i>
    </button>
    <div
      class="kt-grid__item kt-app__toggle kt-app__aside kt-app__aside--lg kt-app__aside--fit"
      id="kt_chat_aside"
    >
      <KTPortlet class="kt-portlet--last">
        <template v-slot:body>
          <div class="kt-searchbar">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fa fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('GENERAL.SEARCH')"
                aria-describedby="basic-addon1"
                v-model="keyword"
                v-on:keyup="searchUserLists"
              />
            </div>
          </div>
          <div class="kt-widget kt-widget--users kt-mt-20">
            <b-overlay :show="isUsersListLoading" variant="white" rounded="sm">
              <perfect-scrollbar
                class="kt-scroll kt-scroll--pull"
                :style="usersListHeight"
              >
                <div class="kt-widget__items">
                  <UserItem
                    v-for="user in usersListToLoad"
                    :key="user.id"
                    :id="user.id"
                    :image="user.image"
                    :name="user.name + ' ' + user.last_name"
                    :type="user.type"
                    :is-online="user.isOnline"
                    :unread="user.unread_messages"
                  ></UserItem>
                </div>
              </perfect-scrollbar>
            </b-overlay>
          </div>
        </template>
      </KTPortlet>
    </div>

    <div
      class="kt-grid__item kt-grid__item--fluid kt-app__content"
      id="kt_chat_content"
    >
      <div class="kt-chat">
        <KTPortlet class="kt-portlet--head-lg kt-portlet--last">
          <template v-slot:head>
            <div class="kt-chat__head">
              <div class="kt-chat__left">
                <button
                  type="button"
                  class="btn btn-clean btn-sm btn-icon btn-icon-md kt-hidden-desktop"
                  id="kt_chat_aside_mobile_toggle"
                >
                  <i class="flaticon2-open-text-book"></i>
                </button>
              </div>
              <div class="kt-chat__center">
                <div class="kt-chat__label">
                  <a href="javascript:void(0)" class="kt-chat__title">
                    {{ selectedUser.name + " " + selectedUser.last_name }}
                  </a>
                  <span class="kt-chat__status">
                    <span
                      v-if="selectedUser.isOnline === 1"
                      class="kt-badge kt-badge--dot kt-badge--success"
                    ></span>
                  </span>
                </div>
              </div>
              <div class="kt-chat__right">
                <div class="dropdown dropdown-inline">
                  <button
                    type="button"
                    class="btn btn-clean btn-sm btn-icon btn-icon-md"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="flaticon2-add-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-overlay
              :show="isChatContentLoading"
              variant="white"
              rounded="sm"
            >
              <perfect-scrollbar
                class="kt-scroll kt-scroll--pull"
                id="chat-content-scrollbar"
                :style="chatScrollHeight"
              >
                <div class="kt-chat__messages">
                  <MessageItem
                    v-for="message in messages"
                    :key="message.id"
                    :id="message.id"
                    :type="message.type"
                    :name="selectedUser.name"
                    :image="
                      message.type === 'received' ? selectedUser.image : ''
                    "
                    :time="message.time"
                    :message="message.message"
                    :status="message.status"
                  ></MessageItem>
                </div>
              </perfect-scrollbar>
            </b-overlay>
          </template>
          <template v-slot:foot>
            <div class="kt-chat__input">
              <div class="kt-chat__editor">
                <b-textarea
                  style="height: 50px"
                  :disabled="selectedUser.id === null"
                  id="newMessageInput"
                  :placeholder="$t('GENERAL.TYPE_HERE') + '...'"
                ></b-textarea>
              </div>
              <div class="kt-chat__toolbar">
                <div class="kt_chat__actions">
                  <button
                    type="button"
                    id="kt_send"
                    class="btn btn-brand btn-md btn-upper btn-bold kt-chat__reply"
                    style="color: white;"
                    v-on:click="sendMessage()"
                  >
                    {{ $t("AUTH.GENERAL.SUBMIT_BUTTON") }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTUtil from "@/assets/js/util.js";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import KTOffcanvas from "@/assets/js/offcanvas";
import UserItem from "@/views/pages/chat/UserItem";
import MessageItem from "@/views/pages/chat/MessageItem";
import { mapState } from "vuex";
import {
  LOAD_USERS,
  SEARCH_USERS,
  SEND_MESSAGE,
  SUBSCRIBE_TO_CHANNEL,
  SUBSCRIPTION_MESSAGE
} from "@/store/chat.module";

export default {
  name: "Chat",
  data() {
    return {
      chatScrollHeight: {
        height: "0px",
        position: "relative"
      },
      usersListHeight: {
        height: "0px",
        position: "relative"
      },
      offCanvas: null,
      keyword: null
    };
  },
  components: {
    KTPortlet,
    UserItem,
    MessageItem
  },
  mounted() {
    this.chatScrollHeight.height = this.getChatHeight();
    this.usersListHeight.height = this.getUsersListHeight();
    this.offcanvas = new KTOffcanvas(KTUtil.getByID("kt_chat_aside"), {
      overlay: true,
      baseClass: "kt-app__aside",
      closeBy: "kt_chat_aside_close",
      toggleBy: "kt_chat_aside_mobile_toggle"
    });
    this.$store.dispatch(LOAD_USERS);
    this.$store.dispatch(SUBSCRIBE_TO_CHANNEL);
    let store = this.$store;
    let scroll = this.contentScroll;
    scroll();
    this.channel.bind("message.posted", function(data) {
      store.dispatch(SUBSCRIPTION_MESSAGE, data.message).then(() => {
        scroll();
      });
    });
  },
  methods: {
    getContentHeight() {
      let height;
      height = KTUtil.getViewPort().height;
      if (KTUtil.getByID("kt_header"))
        height = height - KTUtil.actualHeight("kt_header");
      if (KTUtil.getByID("kt_subheader"))
        height = height - KTUtil.actualHeight("kt_subheader");
      if (KTUtil.getByID("kt_footer"))
        height = height - parseInt(KTUtil.css("kt_footer", "height"));
      if (KTUtil.getByID("kt_content")) {
        height =
          height -
          parseInt(KTUtil.css("kt_content", "padding-top")) -
          parseInt(KTUtil.css("kt_content", "padding-bottom"));
      }
      return height;
    },
    getChatHeight() {
      let height;
      let parentEl = KTUtil.getByID("kt_chat_content");
      let chatEl = KTUtil.find(parentEl, ".kt-chat");
      let portletHeadEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__head"
      );
      let portletBodyEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__body"
      );
      let portletFootEl = KTUtil.find(
        parentEl,
        ".kt-portlet > .kt-portlet__foot"
      );
      if (KTUtil.isInResponsiveRange("desktop")) {
        height = this.getContentHeight();
      } else {
        height = KTUtil.getViewPort().height;
      }
      if (chatEl) {
        height =
          height -
          parseInt(KTUtil.css(chatEl, "margin-top")) -
          parseInt(KTUtil.css(chatEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(chatEl, "padding-top")) -
          parseInt(KTUtil.css(chatEl, "padding-bottom"));
      }
      if (portletHeadEl) {
        height = height - parseInt(KTUtil.css(portletHeadEl, "height"));
        height =
          height -
          parseInt(KTUtil.css(portletHeadEl, "margin-top")) -
          parseInt(KTUtil.css(portletHeadEl, "margin-bottom"));
      }
      if (portletBodyEl) {
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "margin-top")) -
          parseInt(KTUtil.css(portletBodyEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "padding-top")) -
          parseInt(KTUtil.css(portletBodyEl, "padding-bottom"));
      }
      if (portletFootEl) {
        height = height - parseInt(KTUtil.css(portletFootEl, "height"));
        height =
          height -
          parseInt(KTUtil.css(portletFootEl, "margin-top")) -
          parseInt(KTUtil.css(portletFootEl, "margin-bottom"));
      }
      height = height - 5;
      return height + "px";
    },
    getUsersListHeight() {
      let height;
      let chatAsideEl = KTUtil.getByID("kt_chat_aside");
      let portletBodyEl = KTUtil.find(
        chatAsideEl,
        ".kt-portlet > .kt-portlet__body"
      );
      let widgetEl = KTUtil.find(chatAsideEl, ".kt-widget.kt-widget--users");
      let searchbarEl = KTUtil.find(chatAsideEl, ".kt-searchbar");
      if (KTUtil.isInResponsiveRange("desktop")) {
        height = this.getContentHeight();
      } else {
        height = KTUtil.getViewPort().height;
      }
      if (chatAsideEl) {
        height =
          height -
          parseInt(KTUtil.css(chatAsideEl, "margin-top")) -
          parseInt(KTUtil.css(chatAsideEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(chatAsideEl, "padding-top")) -
          parseInt(KTUtil.css(chatAsideEl, "padding-bottom"));
      }
      if (widgetEl) {
        height =
          height -
          parseInt(KTUtil.css(widgetEl, "margin-top")) -
          parseInt(KTUtil.css(widgetEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(widgetEl, "padding-top")) -
          parseInt(KTUtil.css(widgetEl, "padding-bottom"));
      }
      if (portletBodyEl) {
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "margin-top")) -
          parseInt(KTUtil.css(portletBodyEl, "margin-bottom"));
        height =
          height -
          parseInt(KTUtil.css(portletBodyEl, "padding-top")) -
          parseInt(KTUtil.css(portletBodyEl, "padding-bottom"));
      }
      if (searchbarEl) {
        height = height - parseInt(KTUtil.css(searchbarEl, "height"));
        height =
          height -
          parseInt(KTUtil.css(searchbarEl, "margin-top")) -
          parseInt(KTUtil.css(searchbarEl, "margin-bottom"));
      }
      height = height - 5;
      return height + "px";
    },
    contentScroll() {
      let chatContent = document.getElementById("chat-content-scrollbar");
      this.$nextTick(() => {
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    },
    sendMessage() {
      const message = document.getElementById("newMessageInput");
      if (message.value) {
        const submitButton = document.getElementById("kt_send");
        submitButton.classList.add(
          "disabled",
          "kt-spinner",
          "kt-spinner--light",
          "kt-spinner--right"
        );
        let payload = {
          user_id: this.selectedUser.id,
          message: message.value
        };
        this.$store
          .dispatch(SEND_MESSAGE, payload)
          .then(() => {
            this.contentScroll();
            message.value = null;
            submitButton.classList.remove(
              "disabled",
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          })
          .catch(() => {
            submitButton.classList.remove(
              "disabled",
              "kt-spinner",
              "kt-spinner--light",
              "kt-spinner--right"
            );
          });
      }
    },
    searchUserLists: function() {
      this.$nextTick(() => {
        if (this.keyword !== null && this.keyword.length > 0) {
          this.$store.dispatch(SEARCH_USERS, this.keyword);
        }
      });
    }
  },
  computed: {
    ...mapState({
      channel: state => state.chat.channel,
      usersList: state => state.chat.usersList,
      filteredUsersList: state => state.chat.filteredUsersList,
      isUsersListLoading: state => state.chat.isUsersListLoading,
      selectedUser: state => state.chat.selectedUser,
      messages: state => state.chat.messages,
      isChatContentLoading: state => state.chat.isChatContentLoading
    }),
    usersListToLoad() {
      if (this.keyword !== null && this.keyword.length > 0) {
        return this.filteredUsersList;
      } else {
        return this.usersList;
      }
    }
  }
};
</script>
